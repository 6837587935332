export const STEPS = {
  STYLE: "style",
  ETHNICITY: "ethnicity",
  BODY: "body",
  FACE: "face",
  SOUL: "soul",
  SUMMARY: "summary",
};

export const STYLES = {
  REALISTIC: "realistic",
  ANIME: "anime",
};

export const ETHNICITIES = [
  { label: "Caucasian", value: "caucasian" },
  { label: "Asian", value: "asian" },
  { label: "Arabic", value: "arabic" },
  { label: "Black", value: "black" },
  { label: "Latina", value: "latina" },
  // { label: "Indian", value: "indian" },
];

export const AGES = [
  { label: "Teen (18+)", value: "18" },
  { label: "20s", value: "20" },
  { label: "30s", value: "30" },
  { label: "Milf", value: "37" },
  { label: "Granny", value: "70" },
];

export const EYES = [
  { label: "Brown", value: "brown" },
  { label: "Blue", value: "blue" },
  { label: "Green", value: "green" },
  { label: "Yellow", value: "yellow" },
  { label: "Red", value: "red" },
];

export const HAIR_STYLES = [
  { label: "Straight", value: "straight" },
  { label: "Short", value: "short" },
  { label: "Braids", value: "braids" },
  { label: "Ponytail", value: "ponytail" },
  { label: "Bun", value: "bun" },
  { label: "Curly", value: "curly" },
  // { label: "Pigtails", value: "pigtails" },
  // { label: "Bangs", value: "bangs" },
  // { label: "Long", value: "long" },
];

export const HAIR_COLORS = [
  { label: "Black", value: "black" },
  { label: "Brunette", value: "brunette" },
  { label: "Blonde", value: "blonde" },
  { label: "Pink", value: "pink" },
  { label: "Redhead", value: "redhead" },
  // { label: "Green", value: "green" },
];

export const BODY_TYPES = [
  { label: "Fit", value: "fit" },
  { label: "Skinny", value: "skinny" },
  { label: "Muscular", value: "muscular" },
  { label: "Chubby", value: "chubby" },
];

export const BREAST_SIZES = [
  { label: "Small", value: "small" },
  { label: "Medium", value: "medium" },
  { label: "Large", value: "large" },
  { label: "Huge", value: "gigantic" },
];

export const BUTT_SIZES = [
  { label: "Small", value: "small" },
  { label: "Medium", value: "medium" },
  { label: "Athletic", value: "athletic" },
  { label: "Large", value: "large" },
];

export const PERSONALITIES = [
  {
    name: "Caregiver",
    value: "caregiver",
    description:
      "Embraces a comforting and supportive role, always there to provide a listening ear and a helping hand.",
  },
  {
    name: "Sage",
    value: "sage",
    description:
      "Embodies wisdom and reflection, often serving as a beacon of guidance and insight.",
  },
  {
    name: "Innocent",
    value: "innocent",
    description:
      "Views the world through rose-colored glasses, exuding optimism and a refreshing simplicity.",
  },
  {
    name: "Jester",
    value: "jester",
    description:
      "Always the source of laughter, bringing light-heartedness and wit to every interaction.",
  },
  {
    name: "Temptress",
    value: "temptress",
    description:
      "Captivates with an alluring and playful charm, always leaving an air of mystery.",
  },
  {
    name: "Dominant",
    value: "dominant",
    description:
      "Exudes confidence and a sense of command, often taking the lead in interactions.",
  },
  {
    name: "Submissive",
    value: "submissive",
    description:
      "Agreeable and accommodating, often putting others' preferences and desires first.",
  },
  {
    name: "Lover",
    value: "lover",
    description:
      "Passionate and deeply emotional, valuing strong emotional bonds and intimate connections.",
  },
  {
    name: "Nympho",
    value: "nympho",
    description:
      "Driven by a strong desire for physical pleasure, always seeking out new and exciting experiences.",
  },
  {
    name: "Mean",
    value: "mean",
    description:
      "Often critical and blunt, with a tendency to express themselves with sharpness and sarcasm.",
  },
  {
    name: "Confidant",
    value: "confidant",
    description:
      "A pillar of trust and support, always ready to listen and offer sage advice.",
  },
  {
    name: "Experimenter",
    value: "experimenter",
    description:
      "Inherently curious and open to new experiences, always on the lookout for the next adventure.",
  },
];

export const VOICES = [
  // { label: "Sexy Villain 😈", value: "sexy_villain" },
  { label: "Cute", value: "cute" },
  { label: "Teen", value: "sweet_teen" },
  { label: "Anime", value: "anime" },
  // { label: "Soft", value: "soft" },
  { label: "Dominant", value: "dominant" },
  // { label: "Emotive", value: "emotive" },
];

export const OCCUPATIONS = [
  "Teacher",
  "Nurse",
  "Interior Designer",
  "Beauty Therapist",
  "Student",
  "Soldier",
  "Stewardess",
  "Chef",
  "Photographer",
  "Model",
  "Actress",
  "Librarian",
  "Nutritionist",
  "Florist",
  "Wedding Planner",
  "Fashion Blogger",
  "Yoga Instructor",
  "Childcare Worker",
  "Social Worker",
  "Executive Assistant",
  "Event Coordinator",
  "Makeup Artist",
  "Art Teacher",
  "Dance Instructor",
  "Boutique Owner",
  "Jewelry Maker",
  "Perfumer",
  "Voice Coach",
  "Pastry Chef",
  "Pilates Instructor",
];

export const HOBBIES = [
  "Fitness",
  "Vlogging",
  "Traveling",
  "Hiking",
  "Gaming",
  "Partying",
  "Anime",
  "Cosplay",
  "Writing",
  "DIY Crafting",
  "Photography",
  "Volunteering",
  "Cars",
  "Art",
  "Watching Netflix",
  "Manga and Anime",
  "Martial Arts",
  "Baking",
  "Reading",
  "Painting",
  "Knitting",
  "Yoga",
  "Surfing",
  "Playing Piano",
  "Bird Watching",
  "Skateboarding",
  "Pottery",
  "Stand-Up Comedy",
  "Astrology",
  "Mountain Biking",
  "Scuba Diving",
  "Salsa Dancing",
  "Antiquing",
  "Calligraphy",
  "Horseback Riding",
  "Archery",
  "Chess",
];

export const RELATIONSHIPS = [
  {
    name: "Step Sister",
    value: "step-sister",
    // prompt:
    //   "{{char}} is the Step Sister of {{user}}. She builds a familial relationship with a sexual tone based on shared family dynamics, engaging in sibling activities and conversations.",
  },
  {
    name: "Step Mom",
    value: "step-mom",
    // prompt:
    //   "{{char}} is the Step Mom of {{user}}. She takes on a nurturing role, offering guidance and care.",
  },
  {
    name: "Girlfriend",
    value: "girlfriend",
    // prompt:
    //   "{{char}} is the Girlfriend of {{user}}. She engages in intimate and caring conversation, showing interest in {{user}}'s day-to-day life and expressing affection.",
  },
  {
    name: "Sex Friend",
    value: "sex-friend",
    // prompt:
    //   "{{char}} is a Sex Friend of {{user}}. She communicates with a flirtatious and playful tone, maintaining boundaries of respect while engaging in light-hearted and suggestive banter.",
  },
];

export const CLOTHES = [
  "Bikini",
  "Skirt",
  "Cheerleader Outfit",
  "Pencil Dress",
  "Long Dress",
  "Women's Basketball Jersey",
  "Soccer Uniform",
  "Tennis Outfit",
  "Swimsuit",
  "Sportswear",
  "Wedding Dress",
  "Fancy Dress",
  "Witch Costume",
  "Summer Dress",
  "Lingerie",
  "Latex Outfit",
  "School Uniform",
  "Yoga Outfit",
  "Leather Outfit",
  "Princess Dress",
  "Corset",
  "Angel Costume",
  "Maid Outfit",
  "Medieval Armor",
  "Lab Coat",
  "Cowboy Attire",
  "Carnival Costume",
  "Casual Wear",
  "Nurse Uniform",
  "Santa Claus Outfit",
  "Police Uniform",
  "Steampunk Gear",
  "Superhero Costume",
  "Teacher's Attire",
  "Viking Costume",
  "Firefighter Gear",
  "Military Uniform",
  "Construction Outfit",
  "Long Coat",
  "Hoodie",
  "Jeans",
  "Leggings",
  "Ninja Outfit",
  "Pajamas",
  "Clown Costume",
  "Flight Attendant Uniform",
  "Barista Outfit",
  "Belly Dancer Costume",
  "Goth Attire",
  "Pirate Costume",
  "Prisoner Outfit",
  "Race Driver Suit",
  "Hijab",
  "Tutu",
  "Poncho",
  "Hip Hop Attire",
  "Skatepark Gear",
  "Lumberjack Outfit",
  "Adventurer Gear",
  "Sailor Uniform",
  "Golf Attire",
  "Onesie",
  "Oversized Shirt",
  "Tribal Attire",
  "Secretary Outfit",
  "Pop Star Costume",
];
