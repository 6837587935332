import { Button } from "../../../components/ui/button";
import { cn } from "../../../utils/tailwindHelpers";
import { ArrowRight02Icon } from "@hugeicons/react";

function NextButton({ className, onClick, disabled = false }) {
  return (
    <Button
      onClick={onClick}
      variant="pink"
      size="lg"
      className={cn(
        "px-3 py-3 h-12 flex-1 disabled:opacity-60 disabled:bg-opacity-20 shadow-xl shadow-black/20 font-black bg-gradient-to-b from-rose-500 to-rose-400 ring-2 ring-rose-200",
        className
      )}
      disabled={disabled}
    >
      Next{" "}
      <ArrowRight02Icon
        variant="solid"
        className="hidden md:block w-5 h-5 ml-2"
      />
    </Button>
  );
}

export default NextButton;
