import { Button } from "../../../components/ui/button";
import { cn } from "../../../utils/tailwindHelpers";
import { ArrowLeft02Icon } from "@hugeicons/react";

function PrevButton({ className, onClick, disabled = false }) {
  return (
    <Button
      onClick={onClick}
      variant="outline"
      size="lg"
      className={cn("px-3 py-3 h-12 font-black", className)}
      disabled={disabled}
    >
      <ArrowLeft02Icon
        variant="solid"
        className="hidden md:block w-5 h-5 mr-2"
      />{" "}
      Previous
    </Button>
  );
}

export default PrevButton;
