import {
  Edit02Icon,
  GiftIcon,
  Loading03Icon,
  PauseIcon,
  PlayIcon,
  SparklesIcon,
  StarsIcon,
} from "@hugeicons/react";
import { Button } from "../../../../components/ui/button";
import SelectionHeader from "../SelectionHeading";
import Footer from "../Footer";
import PrevButton from "../PrevButton";
import SummaryCard from "../SummaryCard";
import useBuilderStore from "../../../../stores/builderStore";
import { capitalize } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  BODY_TYPES,
  BREAST_SIZES,
  BUTT_SIZES,
  ETHNICITIES,
  EYES,
  HAIR_COLORS,
  HAIR_STYLES,
  PERSONALITIES,
  RELATIONSHIPS,
  VOICES,
  STEPS,
  STYLES,
} from "../../../../utils/builderConstants";
import ReactImageFallback from "react-image-fallback";
import { useAuthModal } from "../../hooks/useAuthModal";
import { cn } from "../../../../utils/tailwindHelpers";

function StepSummary({ realisticImage, animeImage }) {
  const { config, step, cmaiAssetPathForStyle, cmaiAssetPath, setStep } =
    useBuilderStore();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (step === STEPS.SUMMARY) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [step]);

  const authModal = useAuthModal();

  const ethnicityData = ETHNICITIES.find(
    (ethnicity) => ethnicity.value == config.ethnicity
  );
  const relationshipData = RELATIONSHIPS.find(
    (relationship) => relationship.value == config.relationship
  );
  const ageValue = config.age;
  const eyesColorData = EYES.find((eye) => eye.value == config.eyesColor);
  const hairColorData = HAIR_COLORS.find(
    (hairColor) => hairColor.value == config.hairColor
  );
  const hairStyleData = HAIR_STYLES.find(
    (hairStyle) => hairStyle.value == config.hairStyle
  );
  const bodyTypeData = BODY_TYPES.find(
    (bodyType) => bodyType.value == config.bodyType
  );
  const breastSizeData = BREAST_SIZES.find(
    (breastSize) => breastSize.value == config.breastSize
  );
  const buttSizeData = BUTT_SIZES.find(
    (buttSize) => buttSize.value == config.buttSize
  );
  const personalityData = PERSONALITIES.find(
    (personality) => personality.value == config.personality
  );
  const voiceData = VOICES.find((voice) => voice.value == config.voice);

  // Voice
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(
    new Audio(cmaiAssetPath(`/voices/${voiceData.value}.mp3`))
  );

  const handlePlayPauseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    audioRef.current.addEventListener("play", () => {
      setPlaying(true);
    });

    audioRef.current.addEventListener("pause", () => {
      setPlaying(false);
    });

    audioRef.current.addEventListener("ended", () => {
      setPlaying(false);
    });
  }, []);

  const [creating, setCreating] = useState(false);

  const handleCreate = async () => {
    setCreating(true);

    window.plausible && window.plausible("AI Build Created");

    authModal.open();
  };

  const finalRealisticImage = realisticImage
    ? `/landing/builder/female/${realisticImage}`
    : cmaiAssetPath("style/realistic_v2.webp");
  const finalAnimeImage = animeImage
    ? `/landing/builder/female/${animeImage}`
    : cmaiAssetPath("style/anime_v2.webp");

  return (
    <div>
      <SelectionHeader title="Summary" />

      <div
        className={cn(
          "transition-all duration-500 fixed inset-0 flex flex-col items-center justify-center bg-black/50 backdrop-blur-xl z-[700]",
          loading ? "opacity-100" : "opacity-0 z-[-1]"
        )}
      >
        <Loading03Icon variant="twotone" className="w-12 h-12 animate-spin" />
        <h4 className="text-white text-lg font-bold mt-4">
          Preparing your AI...
        </h4>
      </div>

      <div className="flex flex-col gap-3 mt-4">
        {/* Header - mobile */}
        <div className="flex md:hidden flex-row items-stretch gap-3">
          {/* Style */}
          <div className="flex-1">
            <SummaryCard label={capitalize(config.style)}>
              <ReactImageFallback
                src={
                  config.style === STYLES.REALISTIC
                    ? finalRealisticImage
                    : finalAnimeImage
                }
                fallbackImage={cmaiAssetPath(
                  config.style === STYLES.REALISTIC
                    ? "style/realistic_v2.webp"
                    : "style/anime_v2.webp"
                )}
                className="w-full h-full object-cover rounded-2xl"
              />
            </SummaryCard>
          </div>

          {/* Ethnicity */}
          <div className="flex-1">
            <SummaryCard label={capitalize(ethnicityData.label)}>
              <img
                src={cmaiAssetPathForStyle(
                  `/ethnicity/${ethnicityData.value}.webp`
                )}
                className="w-full h-full object-cover rounded-2xl"
              />
            </SummaryCard>
          </div>

          <div className="flex-1 flex flex-col gap-3">
            {/* Relationship */}
            <SummaryCard background>
              <img
                src={cmaiAssetPath(`/relationships/${config.relationship}.svg`)}
                className="w-12 h-12 object-cover"
              />

              <h5 className="mt-2 font-bold text-sm md:text-base text-center">
                {capitalize(relationshipData.name)}
              </h5>
            </SummaryCard>
            {/* Voice */}
            <SummaryCard background className="pointer-events-auto">
              <div className="flex flex-col items-center justify-center flex-1 relative rounded-xl overflow-hidden w-full px-3 py-2 bg-accent/30 border-transparent">
                {/* Play/Pause */}
                <button
                  className="w-10 h-10 flex items-center justify-center mr-2 bg-white rounded-full text-rose-600"
                  onClick={handlePlayPauseClick}
                >
                  {playing ? (
                    <PauseIcon className="w-6 h-6" />
                  ) : (
                    <PlayIcon className="w-6 h-6" />
                  )}
                </button>

                <h5 className="mt-2 font-bold text-sm text-center">
                  {voiceData.label}
                </h5>
              </div>
            </SummaryCard>
          </div>
        </div>

        {/* Header - tablet+ */}
        <div className="hidden md:flex flex-row items-stretch gap-3">
          {/* Style */}
          <div className="flex-1 h-full">
            <SummaryCard label={capitalize(config.style)}>
              <ReactImageFallback
                src={
                  config.style === STYLES.REALISTIC
                    ? finalRealisticImage
                    : finalAnimeImage
                }
                fallbackImage={cmaiAssetPath(
                  config.style === STYLES.REALISTIC
                    ? "style/realistic_v2.webp"
                    : "style/anime_v2.webp"
                )}
                className="w-full h-full object-cover rounded-2xl"
              />
            </SummaryCard>
          </div>

          {/* Others */}
          <div className="flex-1 items-stretch flex flex-col gap-3">
            {/* Relationship */}
            <SummaryCard background>
              <img
                src={cmaiAssetPath(`/relationships/${config.relationship}.svg`)}
                className="w-16 h-16 object-cover"
              />

              <h5 className="mt-3 font-bold text-sm md:text-base">
                {capitalize(relationshipData.name)}
              </h5>
            </SummaryCard>

            {/* Ethnicity, Age, Eyes */}
            <div className="flex-1 flex flex-row gap-3">
              {/* Ethnicity */}
              <SummaryCard label={capitalize(ethnicityData.label)}>
                <img
                  src={cmaiAssetPathForStyle(
                    `/ethnicity/${ethnicityData.value}.webp`
                  )}
                  className="w-full h-full object-cover rounded-2xl"
                />
              </SummaryCard>

              {/* Voice */}
              <SummaryCard background className="pointer-events-auto">
                <div className="flex flex-col items-center justify-center flex-1 relative rounded-xl overflow-hidden w-full px-3 py-2 bg-accent/30 border-transparent">
                  {/* Play/Pause */}
                  <button
                    className="w-10 h-10 flex items-center justify-center mr-2 bg-white rounded-full text-rose-600"
                    onClick={handlePlayPauseClick}
                  >
                    {playing ? (
                      <PauseIcon className="w-6 h-6" />
                    ) : (
                      <PlayIcon className="w-6 h-6" />
                    )}
                  </button>

                  <h5 className="mt-2 font-bold text-sm md:text-base text-center">
                    {voiceData.label}
                  </h5>
                </div>
              </SummaryCard>
            </div>

            <div className="flex-1 flex flex-row items-stretch gap-3">
              {/* Age */}
              <SummaryCard background>
                <span className="text-sm md:text-lg font-semibold">
                  <b className="font-bold block text-center text-2xl">
                    {ageValue}
                  </b>{" "}
                  <span className="text-muted-foreground">Years</span>
                </span>
              </SummaryCard>
            </div>
          </div>
        </div>

        {/* Age - mobile */}
        <div className="md:hidden flex flex-row items-stretch gap-3">
          {/* Age */}
          <SummaryCard background className={"py-3 h-auto"}>
            <span className="text-sm md:text-lg font-semibold">
              <b className="font-bold block text-center text-2xl">{ageValue}</b>{" "}
              <span className="text-muted-foreground">Years</span>
            </span>
          </SummaryCard>
        </div>

        {/* Body Type, Butt, Breasts, Eyes, Hair Style, Hair Color */}
        <div className="grid grid-cols-3 gap-3 lg:grid-cols-6">
          {/* Body Type */}
          <SummaryCard
            className="aspect-square"
            label={capitalize(bodyTypeData.label)}
          >
            <img
              src={cmaiAssetPathForStyle(
                `/body_type/${bodyTypeData.value}.webp`
              )}
              className="w-full h-full object-cover rounded-2xl"
            />
          </SummaryCard>

          {/* Butt */}
          <SummaryCard
            className="aspect-square"
            label={capitalize(buttSizeData.label)}
          >
            <img
              src={cmaiAssetPathForStyle(`/butt/${buttSizeData.value}.webp`)}
              className="w-full h-full object-cover rounded-2xl"
            />
          </SummaryCard>

          {/* Breasts */}
          <SummaryCard
            className="aspect-square"
            label={capitalize(breastSizeData.label)}
          >
            <img
              src={cmaiAssetPathForStyle(
                `/breast/${breastSizeData.value}.webp`
              )}
              className="w-full h-full object-cover rounded-2xl"
            />
          </SummaryCard>

          {/* Eyes */}
          <SummaryCard
            className="aspect-square"
            label={capitalize(eyesColorData.label)}
          >
            <img
              src={cmaiAssetPathForStyle(`/eyes/${eyesColorData.value}.webp`)}
              className="w-full h-full object-cover object-top rounded-2xl"
            />
          </SummaryCard>

          {/* Hair Style */}
          <SummaryCard
            className="aspect-square"
            label={capitalize(hairStyleData.label)}
          >
            <img
              src={cmaiAssetPathForStyle(
                `/hair_style/${hairStyleData.value}.webp`
              )}
              className="w-full h-full object-cover  rounded-2xl"
            />
          </SummaryCard>

          {/* Hair Color */}
          <SummaryCard
            className="aspect-square"
            label={capitalize(hairColorData.label)}
          >
            <img
              src={cmaiAssetPathForStyle(
                `/hair_color/${hairColorData.value}.webp`
              )}
              className="w-full h-full object-cover  rounded-2xl"
            />
          </SummaryCard>
        </div>
      </div>

      <Footer className={"gap-4"}>
        <PrevButton onClick={() => setStep(STEPS.SOUL)} />

        <Button
          variant="pink"
          size="lg"
          onClick={handleCreate}
          className="w-full flex-1 relative shadow-xl h-12 bg-gradient-to-b from-rose-500 to-rose-400 ring-2 ring-rose-200"
        >
          <div>
            <span className="font-black">Bring my AI to Life</span>
          </div>
          <SparklesIcon variant="solid" className="ml-2" />

          <span className="absolute top-0 right-0 rounded-bl-xl rounded-tr-xl bg-rose-700 text-xs px-2 py-[2px] inline-flex items-center font-bold">
            <GiftIcon className="w-3 h-3 inline-block mr-1" variant="solid" />{" "}
            Free
          </span>
        </Button>
      </Footer>
    </div>
  );
}

export default StepSummary;
