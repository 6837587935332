import Footer from "../Footer";
import PrevButton from "../PrevButton";
import NextButton from "../NextButton";
import { ETHNICITIES, STEPS } from "../../../../utils/builderConstants";
import useBuilderStore from "../../../../stores/builderStore";
import SelectionCard from "../SelectionCard";
import SelectionHeading from "../SelectionHeading";
import ParameterSection from "../ParameterSection";
import { Slider } from "../../../../components/ui/slider";
import { useEffect } from "react";

const DEFAULT_AGE = 20;

function StepEthnicity() {
  const { setStep, config, setConfig, cmaiAssetPathForStyle } =
    useBuilderStore();

  // useCmaiAutocontinue(STEPS.BODY, ["ethnicity", "age"]);

  const setEthnicity = (ethnicity) => {
    setConfig({ ethnicity });
  };

  const currentAge = config?.age;
  const setAge = (age) => setConfig({ ...config, age });

  useEffect(() => {
    if (!config?.age) setAge(DEFAULT_AGE);
  }, [config]);

  return (
    <div>
      <ParameterSection>
        <SelectionHeading heading="Choose Ethnicity" />
        <div className="grid grid-cols-2 md:grid-cols-5 gap-5">
          {ETHNICITIES.map((ethnicity) => (
            <SelectionCard
              key={ethnicity.value}
              value={ethnicity.value}
              currentValue={config.ethnicity}
              onClick={setEthnicity}
              label={ethnicity.label}
            >
              <img
                src={cmaiAssetPathForStyle(`ethnicity/${ethnicity.value}.webp`)}
                alt={ethnicity.label}
                className="card-image"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <ParameterSection>
        <SelectionHeading heading="Choose Age" />

        <div className="flex items-center justify-center mt-4">
          <span className="rounded-xl bg-gradient-to-b from-rose-500 to-rose-700 px-3 py-1.5 font-medium">
            <b className="font-bold">{currentAge}</b> Years
          </span>
        </div>

        <div className="flex items-center font-bold mt-6 gap-3 w-full max-w-md mx-auto">
          <span>18+</span>
          <Slider
            value={[currentAge]}
            onValueChange={([age]) => setAge(age)}
            min={18}
            max={60}
          />
          <span>60+</span>
        </div>
      </ParameterSection>

      <Footer>
        <PrevButton onClick={() => setStep(STEPS.STYLE)} />
        <NextButton
          disabled={!config.ethnicity}
          onClick={() => setStep(STEPS.BODY)}
        />
      </Footer>
    </div>
  );
}

export default StepEthnicity;
