import { Head } from "@inertiajs/react";
import Header from "./components/Header";
import useBuilderStore from "../../stores/builderStore";
import { STEPS } from "../../utils/builderConstants";
import StepStyle from "./components/steps/StepStyle";
import StepEthnicity from "./components/steps/StepEthnicity";
import StepBody from "./components/steps/StepBody";
import StepFace from "./components/steps/StepFace";
import StepSoul from "./components/steps/StepSoul";
import StepSummary from "./components/steps/StepSummary";
import { useEffect } from "react";

export default function CreateAIFull({ initialData, var1, var2, goon, title }) {
  const { step, setStep, setConfig } = useBuilderStore();

  useEffect(() => {
    if (initialData && initialData._step) {
      setStep(initialData._step);
      setConfig({ ...initialData });
    }
  }, [initialData]);

  return (
    <>
      <Head title={title} />
      <Header goon={goon} />

      <div className="mt-10 px-4 w-full max-w-5xl mx-auto pb-[100px]">
        {step === STEPS.STYLE && (
          <StepStyle realisticImage={var1} animeImage={var2} />
        )}
        {step === STEPS.ETHNICITY && <StepEthnicity />}
        {step === STEPS.BODY && <StepBody />}
        {step === STEPS.FACE && <StepFace />}
        {step === STEPS.SOUL && <StepSoul />}
        {step === STEPS.SUMMARY && (
          <StepSummary realisticImage={var1} animeImage={var2} />
        )}
      </div>
    </>
  );
}
