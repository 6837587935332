import { create } from "zustand";
import { STEPS } from "../utils/builderConstants";

const useBuilderStore = create((set, get) => ({
  step: STEPS.STYLE,
  config: {},
  setStep: (newStep) =>
    set((state) => {
      const updatedState = { step: newStep };
      console.log("updatedState", updatedState);
      backendUpdateCMAIConfig(updatedState, state.config);
      return updatedState;
    }),
  setConfig: (newConfig) =>
    set((state) => {
      const updatedConfig = { ...state.config, ...newConfig };
      backendUpdateCMAIConfig({ step: state.step }, updatedConfig);
      return { config: updatedConfig };
    }),
  cmaiAssetPath: (path, fallback = null) => {
    return fallback || `/landing/cmai/${path}`;
  },
  cmaiAssetPathForStyle: (path) => {
    const state = get();
    const style = state.config?.style || "";
    return `/landing/cmai/_${style}/${path}`;
  },
}));

const backendUpdateCMAIConfig = (stepData, configData) => {
  configData._step = stepData.step;

  if (configData._step && !configData.style) {
    console.log("No style found, skipping save");
    return;
  }

  fetch("/cmai_save", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
    },
    body: JSON.stringify({
      data: {
        ...configData,
      },
    }),
  }).catch((error) => console.error("Error saving data:", error));
};

export default useBuilderStore;
