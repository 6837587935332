import ReactImageFallback from "react-image-fallback";
import SelectionCard from "../SelectionCard";
import useBuilderStore from "../../../../stores/builderStore";
import { STEPS, STYLES } from "../../../../utils/builderConstants";
import Footer from "../Footer";
import NextButton from "../NextButton";
import SelectionHeading from "../SelectionHeading";

function StepStyle({ realisticImage, animeImage }) {
  const { cmaiAssetPath, config, setConfig, setStep } = useBuilderStore();

  const setStyle = (style) => {
    setConfig({ style });
  };

  return (
    <div>
      <SelectionHeading heading="Choose her Style" />
      <div className="flex items-center justify-center gap-5 md:gap-10">
        <SelectionCard
          value={STYLES.REALISTIC}
          currentValue={config.style}
          onClick={setStyle}
          label="Realistic"
          className="flex-1"
        >
          <ReactImageFallback
            src={
              realisticImage
                ? `/landing/builder/female/${realisticImage}`
                : cmaiAssetPath("style/realistic_v2.webp")
            }
            fallbackImage={cmaiAssetPath("style/realistic_v2.webp")}
            alt="Realistic"
            className="w-full h-[350px] md:h-[400px] lg:h-[530px] object-cover transition-all duration-500 group-hover:scale-105 card-image"
          />
        </SelectionCard>

        <SelectionCard
          value={STYLES.ANIME}
          currentValue={config.style}
          onClick={setStyle}
          label="Anime"
          className="flex-1"
        >
          <ReactImageFallback
            src={
              animeImage
                ? `/landing/builder/female/${animeImage}`
                : cmaiAssetPath("style/anime_v2.webp")
            }
            fallbackImage={cmaiAssetPath("style/anime_v2.webp")}
            alt="Anime"
            className="w-full h-[350px] md:h-[400px] lg:h-[530px] object-cover transition-all duration-500 group-hover:scale-105 card-image"
          />
        </SelectionCard>
      </div>
      <Footer>
        <NextButton
          disabled={!config.style}
          onClick={() => setStep(STEPS.ETHNICITY)}
        />
      </Footer>
    </div>
  );
}

export default StepStyle;
