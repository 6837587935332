import { cn } from "../../../utils/tailwindHelpers";

function Footer({ children, className }) {
  return (
    <>
      <div
        className={cn(
          "flex items-center justify-center md:mt-20 fixed bottom-4 pb-2 pt-2 left-0 px-3 z-[500] md:z-auto md:relative md:bottom-0 md:px-0 gap-3 w-full max-w-xl mx-auto",
          className
        )}
      >
        {children}
      </div>
    </>
  );
}

export default Footer;
