import { cn } from "../../../utils/tailwindHelpers";

function SummaryCard({ label, children, className, background = false }) {
  return (
    <div
      className={cn(
        "flex-1 relative pointer-events-none w-full h-full rounded-2xl flex flex-col items-center justify-center",
        background && "bg-accent/30",
        className
      )}
    >
      {children}
      {label && (
        <div className="absolute bottom-0 left-0 w-full pb-2 md:pb-3 flex items-center justify-center">
          <span className="font-bold text-sm md:text-lg px-3 md:px-4 py-1 bg-black/50 backdrop-blur-sm rounded-full">
            {label}
          </span>
        </div>
      )}
    </div>
  );
}

export default SummaryCard;
