import useBuilderStore from "../../../../stores/builderStore";
import {
  BODY_TYPES,
  BREAST_SIZES,
  BUTT_SIZES,
  STEPS,
} from "../../../../utils/builderConstants";
import Footer from "../Footer";
import NextButton from "../NextButton";
import ParameterSection from "../ParameterSection";
import PrevButton from "../PrevButton";
import SelectionCard from "../SelectionCard";
import SelectionHeading from "../SelectionHeading";

function StepBody() {
  const { setStep, config, setConfig, cmaiAssetPathForStyle } =
    useBuilderStore();

  const setBodyType = (bodyType) => {
    setConfig({ ...config, bodyType });
  };

  const setBreastSize = (breastSize) => {
    setConfig({ ...config, breastSize });
  };

  const setButtSize = (buttSize) => {
    setConfig({ ...config, buttSize });
  };

  return (
    <div>
      <ParameterSection>
        <SelectionHeading heading="Choose Body Type?" />

        <div className="grid grid-cols-4 gap-2 md:gap-5">
          {BODY_TYPES.map((bodyType) => (
            <SelectionCard
              key={bodyType.value}
              value={bodyType.value}
              currentValue={config.bodyType}
              onClick={setBodyType}
              label={bodyType.label}
            >
              <img
                src={cmaiAssetPathForStyle(`body_type/${bodyType.value}.webp`)}
                alt={bodyType.label}
                className="card-image h-[120px] md:h-full object-cover"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <ParameterSection>
        <SelectionHeading heading="Choose Breast Size" />
        <div className="grid grid-cols-4 gap-2 md:gap-5">
          {BREAST_SIZES.map((breastSize) => (
            <SelectionCard
              key={breastSize.value}
              value={breastSize.value}
              currentValue={config.breastSize}
              onClick={setBreastSize}
              label={breastSize.label}
            >
              <img
                src={cmaiAssetPathForStyle(`breast/${breastSize.value}.webp`)}
                alt={breastSize.label}
                className="card-image h-[120px] md:h-full object-cover"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <ParameterSection>
        <SelectionHeading heading="Choose Butt Size" />
        <div className="grid grid-cols-4 gap-2 md:gap-5">
          {BUTT_SIZES.map((buttSize) => (
            <SelectionCard
              key={buttSize.value}
              value={buttSize.value}
              currentValue={config.buttSize}
              onClick={setButtSize}
              label={buttSize.label}
            >
              <img
                src={cmaiAssetPathForStyle(`butt/${buttSize.value}.webp`)}
                alt={buttSize.label}
                className="card-image h-[120px] md:h-full object-cover"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <Footer>
        <PrevButton onClick={() => setStep(STEPS.ETHNICITY)} />
        <NextButton
          disabled={!config.bodyType || !config.breastSize || !config.buttSize}
          onClick={() => setStep(STEPS.FACE)}
        />
      </Footer>
    </div>
  );
}

export default StepBody;
