import useBuilderStore from "../../../stores/builderStore";
import { STEPS } from "../../../utils/builderConstants";

function StepItem({ step, label, isCompleted, isCurrent, onClick }) {
  return (
    <li
      className={`flex items-center justify-center ${
        isCompleted || isCurrent ? "opacity-100" : "opacity-30"
      }`}
      onClick={isCompleted ? onClick : null}
    >
      <span
        className={`text-sm text-primary font-black border-2 mr-2 sm:text-sm w-7 h-7 rounded-full flex items-center justify-center ${
          isCompleted
            ? "border-rose-500 bg-rose-500"
            : isCurrent
            ? "border-rose-500"
            : "bg-transparent"
        }`}
      >
        {step + 1}
      </span>
      <span className="text-primary font-black text-lg hidden md:block">
        {label}
      </span>
    </li>
  );
}

function Header({ goon }) {
  const { step } = useBuilderStore();

  const steps = [
    { label: "Style", step: STEPS.STYLE },
    { label: "Ethnicity", step: STEPS.ETHNICITY },
    { label: "Body", step: STEPS.BODY },
    { label: "Face", step: STEPS.FACE },
    { label: "Soul", step: STEPS.SOUL },
    { label: "Summary", step: STEPS.SUMMARY },
  ];

  const stepIndex = steps.findIndex((item) => item.step === step);

  return (
    <div className="relative bg-accent/30 py-4">
      <ul className="flex justify-around cursor-pointer">
        {steps.map((item, index) => (
          <StepItem
            key={item.step}
            step={index}
            label={item.label}
            isCompleted={stepIndex > index}
            isCurrent={stepIndex === index}
            // onClick={() => setStep(item.step)}
          />
        ))}
      </ul>

      {goon && (
        <div className="absolute top-full -mt-2 left-0 w-full flex items-center justify-center text-center px-4">
          <span className="block bg-gradient-to-t border border-accent bg-black text-white px-3 py-1 rounded-full text-sm max-w-[310px]">
            <b className="text-rose-500">{goon}</b> invited you to create AI
            Cumslut!
          </span>
        </div>
      )}
    </div>
  );
}

export default Header;
