import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

import { cn } from "../../utils/tailwindHelpers";

const Slider = React.forwardRef(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      "relative flex w-full touch-none select-none items-center",
      className
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-3 w-full grow overflow-hidden rounded-full bg-primary/20 cursor-pointer">
      <SliderPrimitive.Range className="absolute h-full bg-rose-500" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="block h-8 w-8 cursor-pointer rounded-full border-[4px] border-white bg-gradient-to-b from-zinc-300 to-zinc-100 shadow transition-colors focus-visible:outline-none focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
