function SelectionHeading({ heading, subheading }) {
  return (
    <div className="mb-5 md:mb-10">
      <h2 className="text-xl text-primary md:text-2xl font-black text-center">
        {heading}
      </h2>
      {subheading && (
        <p className="text-muted-foreground text-sm text-center font-semibold mt-1">
          {subheading}
        </p>
      )}
    </div>
  );
}

export default SelectionHeading;
