import clsx from "clsx";
import { cn } from "../../../utils/tailwindHelpers";

function SelectionCard({
  currentValue,
  value,
  onClick,
  label,
  children,
  className,
}) {
  return (
    <div
      className={cn(
        "cmai-card cmai-selection relative rounded-3xl overflow-hidden group cursor-pointer bg-accent/30",
        currentValue === value && "active",
        !!currentValue && currentValue !== value && "opacity-20",
        className
      )}
      onClick={() => onClick(value)}
    >
      <div>
        {children}
        {label && (
          <span className="text-xs whitespace-nowrap absolute bottom-2 left-1/2 -translate-x-1/2 px-3 py-1 text-primary bg-black/50 rounded-full backdrop-blur-md md:text-lg font-bold">
            {label}
          </span>
        )}
      </div>
    </div>
  );
}

export default SelectionCard;
