import useBuilderStore from "../../../../stores/builderStore";
import {
  EYES,
  HAIR_COLORS,
  HAIR_STYLES,
  STEPS,
} from "../../../../utils/builderConstants";
import ParameterSection from "../ParameterSection";
import SelectionCard from "../SelectionCard";
import SelectionHeading from "../SelectionHeading";
import Footer from "../Footer";
import PrevButton from "../PrevButton";
import NextButton from "../NextButton";

function StepFace() {
  const { config, setConfig, cmaiAssetPathForStyle, setStep } =
    useBuilderStore();

  const setHairStyle = (hairStyle) => {
    setConfig({ ...config, hairStyle });
  };

  const setHairColor = (hairColor) => {
    setConfig({ ...config, hairColor });
  };

  const setEyesColor = (eyesColor) => {
    setConfig({ ...config, eyesColor });
  };

  return (
    <div>
      <ParameterSection>
        <SelectionHeading heading="Choose Hair Style" />

        <div className="grid grid-cols-3 md:grid-cols-6 gap-5">
          {HAIR_STYLES.map((hairStyle) => (
            <SelectionCard
              key={hairStyle.value}
              value={hairStyle.value}
              currentValue={config.hairStyle}
              onClick={setHairStyle}
              label={hairStyle.label}
            >
              <img
                src={cmaiAssetPathForStyle(
                  `hair_style/${hairStyle.value}.webp`
                )}
                alt={hairStyle.label}
                className="card-image h-[120px] md:h-full object-cover"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <ParameterSection>
        <SelectionHeading heading="Choose Hair Color" />

        <div className="grid grid-cols-5 gap-5">
          {HAIR_COLORS.map((hairColor) => (
            <SelectionCard
              key={hairColor.value}
              value={hairColor.value}
              currentValue={config.hairColor}
              onClick={setHairColor}
              label={hairColor.label}
            >
              <img
                src={cmaiAssetPathForStyle(
                  `hair_color/${hairColor.value}.webp`
                )}
                alt={hairColor.label}
                className="card-image h-[80px] md:h-full object-cover"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <ParameterSection>
        <SelectionHeading heading="Choose Eye Color" />

        <div className="grid grid-cols-5 gap-5">
          {EYES.map((eye) => (
            <SelectionCard
              key={eye.value}
              value={eye.value}
              currentValue={config.eyesColor}
              onClick={setEyesColor}
              label={eye.label}
            >
              <img
                src={cmaiAssetPathForStyle(`eyes/${eye.value}.webp`)}
                alt={eye.label}
                className="card-image h-[80px] md:h-full object-cover object-[center_-20px] md:object-[center_-30px]"
              />
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <Footer>
        <PrevButton onClick={() => setStep(STEPS.BODY)} />
        <NextButton
          disabled={!config.hairStyle || !config.hairColor || !config.eyesColor}
          onClick={() => setStep(STEPS.SOUL)}
        />
      </Footer>
    </div>
  );
}

export default StepFace;
