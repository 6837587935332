import Footer from "../Footer";
import PrevButton from "../PrevButton";
import NextButton from "../NextButton";
import {
  RELATIONSHIPS,
  STEPS,
  VOICES,
} from "../../../../utils/builderConstants";
import useBuilderStore from "../../../../stores/builderStore";
import ParameterSection from "../ParameterSection";
import SelectionHeading from "../SelectionHeading";
import SelectionCard from "../SelectionCard";
import { cn } from "../../../../utils/tailwindHelpers";
import { useEffect, useRef, useState } from "react";
import { PauseIcon, PlayIcon } from "@hugeicons/react";

function StepSoul() {
  const { config, setConfig, cmaiAssetPath, setStep } = useBuilderStore();

  const setVoice = (voice) => {
    setConfig({ ...config, voice });
  };

  const setRelationship = (relationship) => {
    setConfig({ ...config, relationship });
  };

  const [playingVoice, setPlayingVoice] = useState(null);
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlayPauseClick = () => {
    if (playing) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    if (!audioRef.current) return;
    audioRef.current.addEventListener("play", () => {
      setPlaying(true);
    });

    audioRef.current.addEventListener("pause", () => {
      setPlaying(false);
    });

    audioRef.current.addEventListener("ended", () => {
      setPlaying(false);
    });
  }, [audioRef.current]);

  return (
    <div>
      <ParameterSection>
        <SelectionHeading heading={"Choose Voice"} />
        <div className="grid grid-cols-4 gap-3 md:gap-5">
          {VOICES.map((voice) => (
            <SelectionCard
              key={voice.value}
              value={voice.value}
              currentValue={config.voice}
              onClick={setVoice}
              className={cn(
                "flex flex-col items-center justify-center text-center px-3 py-5",
                config.voice === voice.value && "ring-4 ring-rose-500"
              )}
            >
              <div className="flex flex-col items-center justify-center">
                {/* Play button */}
                <button
                  className="w-8 h-8 flex items-center justify-center bg-white rounded-full text-rose-600"
                  onClick={() => {
                    if (audioRef.current) {
                      audioRef.current.pause();
                    }
                    audioRef.current = new Audio(
                      cmaiAssetPath(`/voices/${voice.value}.mp3`)
                    );
                    setPlayingVoice(voice.value);
                    handlePlayPauseClick();
                  }}
                >
                  {playingVoice === voice.value && playing ? (
                    <PauseIcon variant="solid" className="w-5 h-5" />
                  ) : (
                    <PlayIcon variant="solid" className="w-5 h-5" />
                  )}
                </button>

                <h5 className="text-md font-bold mt-2">{voice.label}</h5>
              </div>
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      {/* Relationship */}
      <ParameterSection>
        <SelectionHeading heading={"Choose Relationship"} />

        <div className="grid grid-cols-4 gap-3 md:gap-5">
          {RELATIONSHIPS.map((relationship) => (
            <SelectionCard
              key={relationship.value}
              value={relationship.value}
              currentValue={config.relationship}
              onClick={setRelationship}
              className={cn(
                "flex flex-col items-center justify-center text-center px-3 py-5",
                config.relationship === relationship.value &&
                  "ring-4 ring-rose-500"
              )}
            >
              <img
                src={cmaiAssetPath(`/relationships/${relationship.value}.svg`)}
                alt={relationship.name}
                className="w-10 h-10 mx-auto"
              />
              <h5 className="text-md font-bold mt-2">{relationship.name}</h5>
            </SelectionCard>
          ))}
        </div>
      </ParameterSection>

      <Footer>
        <PrevButton onClick={() => setStep(STEPS.FACE)} />
        <NextButton
          disabled={!config.voice || !config.relationship}
          onClick={() => setStep(STEPS.SUMMARY)}
        />
      </Footer>
    </div>
  );
}

export default StepSoul;
